import { QuestionOption, SurveyQuestionModel } from '../app.model';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild, TemplateRef, HostListener, } from '@angular/core';
import { AppService } from '../app.service';
import { Title } from '@angular/platform-browser';
import { MatDialog } from '@angular/material';
import Swal from 'sweetalert2';
import { SurveyService } from '../survey/survey.service';

@Component({
  selector: 'app-take-survey',
  templateUrl: './take-survey.component.html',
  styleUrls: ['./take-survey.component.css']
})
export class TakeSurveyComponent implements OnInit {
  [x: string]: Object;
  progresValue: number;
  rangeArray: number[];
  public currentStatus = '';
  public programUId = '';
  public loading;
  public feedbackProgramDetails;
  public feedbackInvitations;
  public selectedInvitation: any;
  public selectedParticipant: any;
  public selectedRespondent: any;
  public selectedlanguage = '';
  public feedbackProgramQuestionSet;
  public currentPageNumber = 1;
  public totalPages = 0;
  public nextBtnHidden = false;
  public timers = [7200, 900, 0];
  public testStartTime = 0;
  public continuousFunction;
  public enableTimer = false;
  public testTimeTaken = 0;
  public answeredQuestionsLength: any;
  public participantById: any;
  public respondentById: any;
  public instructionData;
  currentInstructionData: []
  public timerConfig = {
    leftTime: 0,
    notify: [120],
    template: '$!h!:$!m!:$!s!'
  }

  @ViewChild('instructionsModal') instructionsModal: TemplateRef<any>;
  testInstructions: Object;

  constructor(private appService: AppService, private surveyService: SurveyService, private router: Router, private title: Title, public dialog: MatDialog) {
    // let surveyLinkId = localStorage.getItem('surveyLinkId') || '';
    // if (surveyLinkId !== '' && surveyLinkId !== undefined) { 
    //   this.surveyLinkId = surveyLinkId;
    //   this.getSurveyData();
    // } else {
    //   this.getParticipantsForRespondent();
    //   this.surveyLinkId = '';
    // }
    // this.progresValue =this.feedbackProgramQuestionSet.length;
    this.progresValue = 0;
    this.rangeArray = new Array(100);

  }

  ngOnInit() {
    this.GetFeedbackProgramForParticipant();
  }


  public getAllParticipantsById(participantId) {
    this.appService.getParticipantById(participantId).subscribe(response => {
      this.participantById = response;
    }, error => {
      console.log(error);
    })
  }

  public getAllRespondentsById(respondentid) {
    this.appService.getRespondentById(respondentid).subscribe(response => {
      this.respondentById = response;
    }, error => {
      console.log(error);
    })
  }


  GetFeedbackProgramForParticipant() {
    this.loading = true;
    this.appService.GetFeedbackProgramForParticipant().subscribe(
      response => {
        this.feedbackProgramDetails = response;
        this.programUId = this.feedbackProgramDetails.uId;
        this.title.setTitle('HR Anexi Assessments');
        if (this.feedbackProgramDetails.isPublished) {
          this.getFeedbackInvitations();
        } else {
          this.currentStatus = 'Survey-Closed';
        }
        this.loading = false;
      }, error => {
        console.log(error);
        this.loading = false;
      });
  }

  getFeedbackInvitations() {
    this.appService.getFeedbackInvitations().subscribe(response => {
      this.feedbackInvitations = response;
      if (this.feedbackInvitations.length > 0) {
        this.currentStatus = 'Select-Participant';
        this.getQuestionSet();
      } else {
        this.currentStatus = 'Survey-Already-Completed';
      }
    })
  }

  public selectParticipant() {
    localStorage.setItem('selectedInvitationCode', this.selectedInvitation.code);
    let participantid = this.selectedInvitation.participantId;
    let respondentid = this.selectedInvitation.respondentId;
    this.getAllParticipantsById(participantid);
    this.getAllRespondentsById(respondentid);
    this.getAnswerSet();
    if (this.feedbackProgramDetails.languages.length === 1) {
      this.selectedlanguage = this.feedbackProgramDetails.languages[0];
      this.startFeedback();
    } else {
      this.currentStatus = 'Select-Language';
    }
  }

  public startFeedback() {
    if (this.feedbackProgramDetails.showInstruction) {
      this.showInstruction();
    } else {
      this.currentStatus = 'Question-Answer';
      if (this.feedbackProgramDetails.showTimer) {
        this.startTimer();
      }
    }
  }

  public showInstruction() {
    this.currentStatus = 'Show-Instruction'
    this.surveyService.getAllSurveyInstructions(this.programUId).subscribe(
      response => {
        this.instructionData = response;
        this.currentInstructionData = this.instructionData.filter(d =>
          parseInt(d.pageNumber) === this.currentPageNumber);
      })
    this.dialog.open(this.instructionsModal, { width: '70%', height: '90%' }).afterClosed().subscribe(result => {
      this.currentStatus = 'Question-Answer';
      if (this.feedbackProgramDetails.showTimer) {
        this.startTimer();
        this.updateAnswerInterval();
      }
    });
  }

  public getQuestionSet() {
    this.appService.getFeedbackProgramQuestionSet().subscribe(
      response => {
        this.feedbackProgramQuestionSet = response;
        this.feedbackProgramQuestionSet.questions.forEach(q => {
          if (q.pageNumber > this.totalPages) {
            this.totalPages = q.pageNumber;
          }
          //sort
          this.feedbackProgramQuestionSet.questions.forEach(set => {
            set.options.sort((a, b) => a.order - b.order);
          })

        });

      },

      error => {
        console.log('Error in getting data');
        console.log(error);
      });
  }

  public getAnswerSet() {

    this.appService.getAnswerSet(this.selectedInvitation.code).subscribe(
      response => {
        if (response != null) {
          let qResponses = response['responses'];
          let timeTaken = '';
          let pageOfTimeTaken = 0;
          let currentPageNumberSet = false;
          qResponses.forEach(responseElement => {
            const selectedQuestion = this.feedbackProgramQuestionSet.questions.find(q => q.code === responseElement.questionCode);
            if (responseElement.responseCode !== '' && responseElement.responseCode !== null) {
              if (selectedQuestion != null) {
                selectedQuestion.response = responseElement.responseCode;
                selectedQuestion.value = responseElement.value;
                if (this.feedbackProgramDetails.showTimer) {
                  selectedQuestion.timeTaken = responseElement.timeTaken;
                  timeTaken = responseElement.timeTaken;
                  pageOfTimeTaken = selectedQuestion.pageNumber;
                }
              }
            } else {
              if (selectedQuestion.pageNumber >= this.currentPageNumber && !currentPageNumberSet) {
                this.currentPageNumber = selectedQuestion.pageNumber;
                currentPageNumberSet = true;
                if (this.currentPageNumber > pageOfTimeTaken) {
                  timeTaken = '';
                }
              }
            }
          });
          if (timeTaken !== '') {
            this.testTimeTaken = Number(timeTaken);
          }
        }


      },
      error => {
        console.log('Error in getting data');
        console.log(error);
      }
    );
  }

  public startTimer() {
    this.instructionData.forEach(page => {
      this.timers.push((page['time'] * 60));
      this.timerConfig.leftTime = this.timers[this.currentPageNumber - 1] - this.testTimeTaken;
      console.log("this.testTimeTaken", this.testTimeTaken);
      console.log(this.timers, this.timerConfig);
      this.enableTimer = true;
      let date = new Date();
      let startTime = page['time'] * 60;
      this.testStartTime = startTime - this.testTimeTaken;
      window.scrollTo(0, 0);
    });
  }

  public handleEvent(event) {
    if (event.action == 'finished') {
      if (!this.isMaxQuestions()) {
        // this.goToNext();
      } else {
        // this.SubmitTest();
      }
    } else if (event.action === 'notify') {
      alert("You have 2 minutes remaining.");
    }
  }

  public updateAnswerInterval() {
    this.continuousFunction = setInterval(() => {
      // this.saveAnswersInIntervals();
    }, 300000);
  }

  public getCustomizedMessage() {
    let customizedMessageText = '';
    if (this.feedbackProgramDetails.customizedMessage) {
      customizedMessageText = this.feedbackProgramDetails.customizedMessageText;
      customizedMessageText = customizedMessageText.replace('###ParticipantName###', this.selectedInvitation.participantName);
      customizedMessageText = customizedMessageText.replace('###ParticipantEmail###', this.selectedInvitation.participantEmail);
      customizedMessageText = customizedMessageText.replace('###RespondentName###', this.selectedInvitation.respondentName);
      customizedMessageText = customizedMessageText.replace('###RespondentEmail###', this.selectedInvitation.respondentEmail);

      customizedMessageText = customizedMessageText.replace('###PColumn1###', this.participantById.column1);
      customizedMessageText = customizedMessageText.replace('###PColumn2###', this.participantById.column2);
      customizedMessageText = customizedMessageText.replace('###PColumn3###', this.participantById.column3);
      customizedMessageText = customizedMessageText.replace('###PColumn4###', this.participantById.column4);
      customizedMessageText = customizedMessageText.replace('###PColumn5###', this.participantById.column5);
      customizedMessageText = customizedMessageText.replace('###PColumn6###', this.participantById.column6);
      customizedMessageText = customizedMessageText.replace('###PColumn7###', this.participantById.column7);
      customizedMessageText = customizedMessageText.replace('###PColumn8###', this.participantById.column8);
      customizedMessageText = customizedMessageText.replace('###PColumn9###', this.participantById.column9);
      customizedMessageText = customizedMessageText.replace('###PColumn10###', this.participantById.column10);


      customizedMessageText = customizedMessageText.replace('###RColumn1###', this.respondentById.column1);
      customizedMessageText = customizedMessageText.replace('###RColumn2###', this.respondentById.column2);
      customizedMessageText = customizedMessageText.replace('###RColumn3###', this.respondentById.column3);
      customizedMessageText = customizedMessageText.replace('###RColumn4###', this.respondentById.column4);
      customizedMessageText = customizedMessageText.replace('###RColumn5###', this.respondentById.column5);
      customizedMessageText = customizedMessageText.replace('###RColumn6###', this.respondentById.column6);
      customizedMessageText = customizedMessageText.replace('###RColumn7###', this.respondentById.column7);
      customizedMessageText = customizedMessageText.replace('###RColumn8###', this.respondentById.column8);
      customizedMessageText = customizedMessageText.replace('###RColumn9###', this.respondentById.column9);
      customizedMessageText = customizedMessageText.replace('###RColumn10###', this.respondentById.column10);
    }
    return customizedMessageText;
  }

  public viewNextPage() {
    this.currentPageNumber += 1;
    this.testTimeTaken = 0;
    this.enableTimer = false;
    this.nextBtnHidden = this.isMaxQuestions();
    if (this.feedbackProgramDetails.showInstructions) {
      this.showInstruction();
    } else if (this.feedbackProgramDetails.showTimer) {
      this.startTimer();
    }
    window.scrollTo(0, 0);
  }

  getQuestionText(question: SurveyQuestionModel) {
    let questionText = '';

    if (question.dependentOn > 0) {
      if (this.selectedlanguage !== 'English') {
        if (question.texts.find(t => t.language === this.selectedlanguage && t.ifPreviousAnswer === this.feedbackProgramQuestionSet.questions.find(q => q.order === question.dependentOn).response) !== undefined) {
          questionText = question.texts.find(t => t.language === this.selectedlanguage && t.ifPreviousAnswer === this.feedbackProgramQuestionSet.questions.find(q => q.order === question.dependentOn).response).text + ' (';
        }
      }

      if (question.texts.find(t => t.language === 'English' && t.ifPreviousAnswer === this.feedbackProgramQuestionSet.questions.find(q => q.order === question.dependentOn).response) !== undefined) {
        questionText = questionText + question.texts.find(t => t.language === 'English' && t.ifPreviousAnswer === this.feedbackProgramQuestionSet.questions.find(q => q.order === question.dependentOn).response).text;
      }

      if (this.selectedlanguage !== 'English' && questionText !== '') {
        questionText = questionText + ')';
      }
    } else {
      if (this.selectedlanguage !== 'English') {
        questionText = question.texts.find(t => t.language === this.selectedlanguage).text + ' (';
      }

      questionText = questionText + question.texts.find(t => t.language === 'English').text;

      if (this.selectedlanguage !== 'English') {
        questionText = questionText + ')';
      }
    }
    return questionText;
  }

  clearDependentAnswer(question) {
    if (this.feedbackProgramQuestionSet.questions.find(q => q.dependentOn > 0 && q.dependentOn === question.order) !== undefined) {
      this.feedbackProgramQuestionSet.questions.find(q => q.dependentOn > 0 && q.dependentOn === question.order).response = '';
    }
  }

  getQuestionOptionText(question: SurveyQuestionModel, option: QuestionOption) {
    let optionText = '';
    let otherLanguageOptionText = '';
    let englishText = '';
    if (question.dependentOn > 0) {
      if (this.feedbackProgramQuestionSet.questions.find(q => q.order === question.dependentOn).response !== option.ifPreviousAnswer) {
        return '';
      } else {
        if (this.selectedlanguage !== 'English') {
          optionText = option.languageOptions.find(o => o.language === this.selectedlanguage).text + ' (';
        }
        optionText = optionText + option.languageOptions.find(o => o.language === 'English').text;
        if (this.selectedlanguage !== 'English') {
          optionText = optionText + ')';
        }
        return optionText;
      }
    } else {
      if (this.selectedlanguage !== 'English') {
        otherLanguageOptionText = option.languageOptions.find(o => o.language === this.selectedlanguage).text.toString();
      }
      englishText = optionText.toString() + option.languageOptions.find(o => o.language === 'English').text.toString();
      if (this.selectedlanguage !== 'English') {
        optionText = '\u202A' + otherLanguageOptionText + '\u202C' + "  ( " + englishText + " )";
      } else {
        optionText = englishText;
      }
      return optionText;
    }
  }

  getQuestionOptionTextInEnglish(question: SurveyQuestionModel, option: QuestionOption) {
    let optionText = '';
    optionText = optionText + option.languageOptions.find(o => o.language === 'English').text;
    return optionText;
  }

  getQuestionOptionValue(question) {
    let optionValue = '';
    optionValue = question.options.find(d => d.value === question.value).languageOptions.find(o => o.language === 'English').text;
    return optionValue;
  }

  checkboxOptionChanged(e, q, o) {

    if (e.target.checked) {
      if (q.response !== undefined) {

        var n = this.occurrences(q.response, '##', false);

        if (n < q.minSelect || n < q.maxSelect) {
          q.response = q.response + e.target.value + '##';

        } else {
          e.target.checked = false;
        }
      } else {
        q.response = e.target.value + '##';
      }
      // console.log(q.response);
    } else {
      q.response = q.response.replace(e.target.value + '##', '');
      // console.log(q.response);
    }
  }

  occurrences(string, subString, allowOverlapping) {
    string += "";
    subString += "";
    if (subString.length <= 0) return (string.length + 1);

    var n = 0,
      pos = 0,
      step = allowOverlapping ? 1 : subString.length;

    while (true) {
      pos = string.indexOf(subString, pos);
      if (pos >= 0) {
        ++n;
        pos += step;
      } else break;
    }
    return n;
  }

  isThisChecked(q, option) {
    if (q.response !== undefined) {
      let optionValue = '';
      optionValue = option.languageOptions.find(o => o.language === 'English').text;
      if (q.response.indexOf(optionValue) >= 0) {
        return true;
      } else {
        return false;
      }
    }
  }

  isMaxQuestions() {
    return this.feedbackProgramQuestionSet.questions.every(d => d.pageNumber <= this.currentPageNumber);
  }

  isSubmitEnabled() {
    let shouldSubmitBeEnabled = true;
    for (let i = 0; i < this.feedbackProgramQuestionSet.questions.length; ++i) {
      if (this.feedbackProgramQuestionSet.questions[i].response === undefined || this.feedbackProgramQuestionSet.questions[i].response === '' || this.feedbackProgramQuestionSet.questions[i].response === null) {
        shouldSubmitBeEnabled = false;
        break;
      }
    }
    return shouldSubmitBeEnabled;
  }

  isNextEnabled(currentPageNumber) {
    let shouldNextBeEnabled = true;

    for (let i = 0; i < this.feedbackProgramQuestionSet.questions.length; ++i) {
      if (this.feedbackProgramQuestionSet.questions[i].pageNumber === currentPageNumber) {
        if (this.feedbackProgramQuestionSet.questions[i].type !== 'multi-text-mandatory') {
          if (this.feedbackProgramQuestionSet.questions[i].response === undefined || this.feedbackProgramQuestionSet.questions[i].response === '') {
            if (this.getQuestionText(this.feedbackProgramQuestionSet.questions[i]).toLowerCase() !== 'not applicable') {
              shouldNextBeEnabled = false;
              break;
            }
          }
        } else {
          this.feedbackProgramQuestionSet.questions[i].options.forEach(o => {
            if (o.response === undefined || o.response.trim() === '') {
              shouldNextBeEnabled = false;
            }
          });
        }
      }
    }

    const totalQuestionCount = this.feedbackProgramQuestionSet.questions.length;
    const answeredQuestionsCount = this.feedbackProgramQuestionSet.questions.filter(d => d.response !== '' && d.response !== undefined && d.response !== null).length;

    this.answeredQuestionsLength = answeredQuestionsCount;

    if (totalQuestionCount != 0) {
      this.progresValue = (answeredQuestionsCount / totalQuestionCount) * 100;
    }

    return shouldNextBeEnabled && !this.isMaxQuestions();
  }

  goToPrevious(count) {
    window.scrollTo(0, 0);
    this.currentPageNumber -= 1;
    this.nextBtnHidden = this.isMaxQuestions();
  }

  markMultitextResponed(question) {
    let responseString = '';
    question.options.forEach(o => {
      if (o.response !== undefined && o.response.trim() !== '') {
        responseString = responseString + o.response + '#';
      } else {
        responseString = responseString + ' ' + '#';
      }
    });
    question.response = responseString;
  }

  getSavedResponses() {
    let timeTaken = 0;
    if (this.feedbackProgramDetails.showTimer) {
      let date = new Date();
      let stopTime = date.getTime() / 1000;
      timeTaken = Math.ceil(stopTime - this.testStartTime);
    }
    let responses: Response[] = [];
    this.feedbackProgramQuestionSet.questions.forEach(d => {
      const response = new Response();
      response.questionCode = d.code;
      response.responseCode = d.response;
      response.value = d.value;
      if (this.feedbackProgramDetails.showTimer) {
        if (d.pageNumber === this.currentPageNumber) {
          d.timeTaken = timeTaken.toString();
          response.timeTaken = timeTaken.toString();
        } else {
          response.timeTaken = d.timeTaken;
        }
      }
      responses.push(response);
    });
    return responses;
  }

  public constructResponseQuestionSet() {
    let response = {
      programUId: this.feedbackProgramDetails.uId,
      programCode: this.feedbackProgramDetails.code,
      invitationCode: this.selectedInvitation.code,
      participantId: this.selectedInvitation.participantId,
      respondentId: this.selectedInvitation.respondentId,
      responses: this.getSavedResponses()
    };
    return response
  }

  public goToNext() {
    if (this.feedbackProgramDetails.showTimer && this.continuousFunction) {
      clearInterval(this.continuousFunction);
    }

    const responsequestionSet = this.constructResponseQuestionSet();

    // this.viewNextPage();
    this.appService.SaveQuestionSetResponses(responsequestionSet).subscribe(
      response => {
        this.viewNextPage();
      }, error => {
        console.log('API ERROR:', error);
      });
  }

  public saveAnswersInIntervals() {
    const responsequestionSet = this.constructResponseQuestionSet();
    this.appService.SaveQuestionSetResponses(responsequestionSet).subscribe(
      response => {
        console.log(response);
      }, error => {
        console.log('API ERROR:', error);
      });
  }

  public CheckSurveyTOSubmit() {
    let submit = false;
    submit = this.feedbackProgramQuestionSet.questions.every(d => d.response !== '' && d.response !== undefined && d.response !== null);
    return submit;
  }

  SubmitTest() {
    let submit = this.CheckSurveyTOSubmit();
    if (submit) {
      this.loading = true;
      const responsequestionSet = this.constructResponseQuestionSet();
      this.appService.SaveQuestionSetResponses(responsequestionSet).subscribe(
        response => {
          this.loading = false;
          this.currentStatus = 'Survey-Completed';
          this.checkRemainingParticipants();

        }, error => {
          this.loading = false;
          console.log('API ERROR:', error);
        });
    } else {
      Swal.fire('Please answer all questions');
    }
  }

  checkRemainingParticipants() {
    this.appService.getFeedbackInvitations().subscribe(response => {
      this.feedbackInvitations = response;
    });
  }

  showParticipantList() {
    window.location.reload();
  }

  logout() {
    Swal.fire({
      position: 'center',
      allowOutsideClick: false,
      allowEscapeKey: false,
      title: 'Logout',
      type: 'warning',
      text: 'Are you sure to logout?',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      confirmButtonText: 'ok'
    }).then((result) => {
      if (result.value) {
        localStorage.clear();
        this.router.navigate(['/login']);
      }
    });
  }
}

class Response {
  questionCode: string;
  responseCode: string;
  value: number;
  otherResponseText: string;
  timeTaken: string;
}


