import { Title } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from '../shared/shared.service';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})


export class LoginComponent implements OnInit {

  public showSpinner = false;
  public loading = false;
  public emailid;
  public pass;
  public hide = true;
  public accessList;
  public token;
  public response;

  public stsUrl = '';
  constructor(private titleService: Title, private route: Router, public http:HttpClient, private sharedService:SharedService) {
    this.titleService.setTitle('Dashboard');
    this.stsUrl = this.sharedService.getSTSUrl();
  }

  ngOnInit() {
    localStorage.clear();
  }

  // method for login and navigate to other module
  callLoginAPI(mail, pwd) {
    this.loading = true;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };

    const body = 'username=' + mail + '&password=' + pwd +
      '&grant_type=password&client_id=ro.client&client_secret=secret';

    return this.http.post(this.stsUrl + '/connect/token', body, httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  login(mail, pwd) {
    this.loading = true;
    this.showSpinner = true;
    this.callLoginAPI(mail, pwd).subscribe(
      data => {
        console.log(data);
        this.token = data['access_token'];
        localStorage.setItem('token', this.token);
        this.GetAccountType(this.token);
      },
      error => {
        Swal.fire({
          position: 'center',
          title: 'Oops...',
          text: 'Username or Password incorrect',
          type: 'error'
        });
        console.log(error);
        this.loading = false;
        this.showSpinner = false
      }
    );
  }

  GetAccountType(token) {
    this.getAccess(token).subscribe(
      response => {
        localStorage.setItem('AccountType', response.toString()); 
          if (response === 'superadmin') {
            this.route.navigate(['/survey/list']);
          } else if (response === 'Participant') {
            this.route.navigate(['/take-survey-new'])
          }
           else {
            this.route.navigate(['/survey/list']);
          }
      },
      error => {
        console.log(error);
      }
    );
  }

  getAccess(token) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.get(this.sharedService.getAPIUrl() + '/api/Accounts/GetAccountType',
      httpOptions)
      .pipe(retry(3), catchError(this.handleError));
  }



  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(error);
  }
}
