import { Injectable } from '@angular/core';
import { SharedService } from '../shared/shared.service';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { throwError, Subject, Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Injectable()
export class SurveyService {
    private tempinvitationCode = new Subject<string>();
    public tempinvitationCode$ = this.tempinvitationCode.asObservable();

    invitationCode(inviCode: string) {
        this.tempinvitationCode.next(inviCode);
    }

    private apiUrl = '';
    constructor(private httpService: HttpClient, private sharedService: SharedService) {
        this.apiUrl = this.sharedService.getAPIUrl();
    }

    // this method returns list of FEEDBACK
    public getAllFeedbackPrograms() {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        return this.httpService.get(this.apiUrl + '/api/Programs/GetAllFeedbackPrograms', httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }

    public deleteClientReport(data) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json-patch+json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        let body = JSON.stringify(data);
        return this.httpService.post(this.apiUrl + '/api/Programs/DeleteSurveyReports', body, httpOptions).pipe(retry(1), catchError(this.handleError));
    }
//copies Feedback
    public CopyFeedbackProgram(uId) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        return this.httpService.get(this.apiUrl + '/api/Programs/CopyFeedbackProgram?uId=' + uId, httpOptions).pipe(retry(1), catchError(this.handleError));
    }

    public getAllParticipants(uid): Observable<any[]> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        return this.httpService.get<any[]>(this.apiUrl + '/api/Participants/GetAllParticipants?uId=' + uid, httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }

    public getAllRespondents(uid): Observable<any[]> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        return this.httpService.get<any[]>(this.apiUrl + '/api/Participants/getAllRespondents?uId=' + uid, httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }


    public getAllInvitations(uid): Observable<any[]> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        return this.httpService.get<any[]>(this.apiUrl + '/api/Participants/getAllInvitations?uId=' + uid, httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }



    public resendInvitations(model) {
        const data = JSON.stringify(model);
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json-patch+json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        return this.httpService.post(this.apiUrl + '/api/Participants/resendInvitations', data, httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }

    

    public SaveEmailText(model) {
        const data = JSON.stringify(model);
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json-patch+json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        return this.httpService.post(this.apiUrl + '/api/programs/SaveEmailText', data, httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }

    public GetEmailText(model) {
        const data = JSON.stringify(model);
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json-patch+json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        return this.httpService.post(this.apiUrl + '/api/programs/GetEmailText', data, httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }
    

    public resendReminders(model) {
        const data = JSON.stringify(model);
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json-patch+json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        return this.httpService.post(this.apiUrl + '/api/Participants/resendReminders', data, httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }

    public getAllClientReport(uid): Observable<any[]> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        return this.httpService.get<any[]>(this.apiUrl + '/api/Programs/GetAllSurveyReports?surveyUId=' + uid, httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }

    public GetAccessList() {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        return this.httpService.get(this.apiUrl + '/api/Programs/GetAccessList', httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }

    // this method returns a particular survey by it's id
    public getSurvey(uId) {
        return this.httpService.get(this.apiUrl + '/api/Programs/GetFeedbackProgramByUId?uId=' + uId)
            .pipe(retry(1), catchError(this.handleError));
    }



    // API for checking link id present of not / survey submitted or not.

    public isLinkIdPresent(presentId) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        return this.httpService.get(this.apiUrl + '/api/Programs/IsLinkIdPresent?id=' + presentId, httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }

    // this method adds a feedback
    public addNewFeedbackProgram(survey) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json-patch+json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        const formData = JSON.stringify(survey);
        return this.httpService.post(this.apiUrl + '/api/Programs/AddNewFeedbackProgram', formData, httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }

    // this method adds a feedback question
    public addFeedbackQuestion(questionObj) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json-patch+json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        const formData = JSON.stringify(questionObj);
        return this.httpService.post(this.apiUrl + '/api/ProgramQuestionSets/AddQuestion', formData, httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }


    public publishSurvey(uId) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json-patch+json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        // const formData = JSON.stringify(publishsurvey);
        return this.httpService.post(this.apiUrl + '/api/Programs/PublishFeedbackProgram?uId=' + uId , {}, httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }

    public closeSurvey(uId) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json-patch+json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        // const formData = JSON.stringify(closesurvey);
        return this.httpService.post(this.apiUrl + '/api/Programs/CloseFeedbackProgram?uId=' + uId, {}, httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }


//updates feedback questions
    public updateQuestion(questionObj) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json-patch+json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        const formData = JSON.stringify(questionObj);
        return this.httpService.post(this.apiUrl + '/api/ProgramQuestionSets/UpdateQuestion', formData, httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }

    public getSurveyQuestionSet(uId) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json-patch+json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        return this.httpService.get(this.apiUrl + '/api/ProgramQuestionSets/GetByProgramUId?uId=' + uId, httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }


    public getSurveyQuestionSetbylink(linkId) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json-patch+json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        return this.httpService.get(this.apiUrl + '/api/ProgramQuestionSets/GetBySurveyLinkId?id=' + linkId, httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }


    // this method adds a client to the data base
    public addClient(client) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json-patch+json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        const formData = JSON.stringify(client);
        return this.httpService.post(this.apiUrl + '/api/Clients/Post', formData, httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }


    public UploadSurveyFile(uId) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json-patch+json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        const formData = JSON.stringify(uId);
        return this.httpService.post(this.apiUrl + '/api/Programs/UploadProgramFile', formData, httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }

    // for invitation code
    public isInvitationCodePresent(sUId, icode) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json-patch+json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        return this.httpService.get(this.apiUrl + '/api/Programs/IsInvitationCodePresent?sUId=' + sUId + '&invitationCode=' + icode, httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }

    makeFileRequest(uId) {
        // const headers = new Headers();
        // headers.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
        return new Promise((resolve, reject) => {
            const downloadRequest = new XMLHttpRequest();
            downloadRequest.open('GET', this.apiUrl + '/api/Programs/DownloadProgramParticipants?uId=' + uId, true);
            downloadRequest.setRequestHeader('Content-Type', 'application/json');
            downloadRequest.responseType = 'blob';
            downloadRequest.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('token'));
            downloadRequest.onreadystatechange = function () {
                if (downloadRequest.readyState === 4) {
                    if (downloadRequest.status === 200) {
                        resolve(downloadRequest.response);
                    } else {
                        // alert('File not Found, No participant added.');
                        Swal.fire({
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            position: 'center',
                            title: 'Oops...',
                            text: 'File not Found, No participant added!',
                            type: 'error'
                        });
                        reject(downloadRequest.response);
                    }
                }
            };
            downloadRequest.send();
        });
    }

    DownloadRawResponse(uId) {
        return new Promise((resolve, reject) => {
            const downloadRequest = new XMLHttpRequest();
            downloadRequest.open('GET', this.apiUrl + '/api/ProgramQuestionSetResponses/DownloadRawResponse?uId=' + uId, true);
            downloadRequest.setRequestHeader('Content-Type', 'application/json');
            downloadRequest.responseType = 'blob';
            downloadRequest.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('token'));
            downloadRequest.onreadystatechange = function () {
                if (downloadRequest.readyState === 4) {
                    if (downloadRequest.status === 200) {
                        resolve(downloadRequest.response);
                    } else {
                        // alert('File not Found');
                        Swal.fire({
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            position: 'center',
                            title: 'Oops...',
                            text: 'File not found, response not available!',
                            type: 'error'
                        });
                        reject(downloadRequest.response);
                    }
                }
            };
            downloadRequest.send();
        });
    }

    public UpdateFeedbackProgram(survey) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json-patch+json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        let data = JSON.stringify(survey);
        return this.httpService.post(this.apiUrl + '/api/Programs/UpdateFeedbackProgram', data, httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }

    // this method returns all client list
    public getClientList() {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json-patch+json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        return this.httpService.get(this.apiUrl + '/api/Clients/GetAll', httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }


    public SendInvitationEmail(uId) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json-patch+json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        return this.httpService.post(this.apiUrl + '/api/Programs/SendInvitationEmail?uId=' + uId, {}, httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }

    public SendReminderEmail(uId) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json-patch+json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        return this.httpService.post(this.apiUrl + '/api/Programs/SendReminderEmail?uId=' + uId, {}, httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }

//delete feedback
    public deleteSurvey(uId) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json-patch+json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        return this.httpService.get(this.apiUrl + '/api/Programs/DeleteProgram?uId=' + uId, httpOptions).pipe(retry(1), catchError(this.handleError));
    }

// delete feedback questions
    public deleteQuestion(deleteobj) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json-patch+json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }),
            body: deleteobj
        };
        return this.httpService.delete(this.apiUrl + '/api/ProgramQuestionSets/DeleteQuestion', httpOptions).pipe(retry(1), catchError(this.handleError));
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        // return an observable with a user-facing error message
        return throwError(error);
    }



    public SurveyQuestionSetResponses(id) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json-patch+json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        return this.httpService.get(this.apiUrl + '/api/ProgramsQuestionSetResponses/GetResponse?id=' + id, httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }

    public getAllSurveyInstructions(uid) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json-patch+json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        return this.httpService.get(this.apiUrl + '/api/Programs/GetAllProgramInstructions?programUId=' + uid, httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }

    public AddSurveyInstruction(data) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json-patch+json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        const formData = JSON.stringify(data);
        return this.httpService.post(this.apiUrl + '/api/Programs/AddOrUpdateProgramInstruction', formData, httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }

    DeleteSurveyInstructions(id) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        return this.httpService.get(this.apiUrl + '/api/Programs/DeleteProgramInstructions?id=' + id, httpOptions).pipe(retry(1), catchError(this.handleError));
    }

    public uploadCompetency(ProgramUId, file) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        return this.httpService.post(this.apiUrl + '/api/Programs/UploadCompetencyDefination?ProgramUId=' + ProgramUId + '&File=' + file , httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }
    public deleteParticipants(data) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json-patch+json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        let body = JSON.stringify(data);
        return this.httpService.post(this.apiUrl + '/api/Participants/DeleteParticipants', body, httpOptions).pipe(retry(1), catchError(this.handleError));
    }




public SendRaterEmail(uId) {
    const httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json-patch+json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        })
    };
    return this.httpService.post(this.apiUrl + '/api/Programs/SendRaterEmail?uId=' + uId, {}, httpOptions)
        .pipe(retry(1), catchError(this.handleError));
    }
    

    public deleteInvitation(id) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json-patch+json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }),
           
        };
        return this.httpService.delete(this.apiUrl + '/api/Participants/DeleteInvitation?id=' + id, httpOptions).pipe(retry(1), catchError(this.handleError));
    }


    public ResendInvitationEmail(id) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json-patch+json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        return this.httpService.post(this.apiUrl + '/api/Participants/ResendInvitationEmail?respondentId=' + id, {}, httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }

    public ResendReminderEmail(id) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json-patch+json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        return this.httpService.post(this.apiUrl + '/api/Participants/ResendReminderEmail?respondentId=' + id, {}, httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }

    // downloadReport(uId) {
    //     return new Promise((resolve, reject) => {
    //         const downloadRequest = new XMLHttpRequest();
    //         downloadRequest.open('GET', this.apiUrl + '/api/Participants/DownloadPdf?participantUId=' + uId, true);
    //         downloadRequest.setRequestHeader('Content-Type', 'application/json');
    //         downloadRequest.responseType = 'blob';
    //         downloadRequest.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('token'));
    //         downloadRequest.onreadystatechange = function () {
    //             if (downloadRequest.readyState === 4) {
    //                 if (downloadRequest.status === 200) {
    //                     resolve(downloadRequest.response);
    //                 } else {
    //                     // alert('File not Found');
    //                     debugger
    //                     Swal.fire({
    //                         allowOutsideClick: false,
    //                         allowEscapeKey: false,
    //                         position: 'center',
    //                         title: 'Oops...',
    //                         text: 'Report is not available!',
    //                         type: 'error'
    //                     });
    //                     reject(downloadRequest.response);
    //                 }
    //             }
    //         };
    //         downloadRequest.send();
    //     });
    // }


    downloadReport(uId) {
        const options = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        return this.httpService.get(this.apiUrl + '/api/Participants/DownloadPdf?participantUId=' + uId, { ...options, observe: 'response', responseType: 'blob' });
    }

    // this method returns list of Competency
    public getAllCompetency() {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        return this.httpService.get(this.apiUrl + '/api/Programs/GetAllCompetency', httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }
   // this method returns list of Competency bu Id
    public getCompetencyById(id) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json-patch+json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        return this.httpService.get(this.apiUrl + '/api/Programs/GetCompetencyById?id=' + id, httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }
    public addNewCompetency(competency) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json-patch+json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        const formData = JSON.stringify(competency);
        return this.httpService.post(this.apiUrl + '/api/Programs/AddOrUpdateCompetency', formData, httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }

    //delete competency
    public deleteCompetency(competencyId) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json-patch+json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        return this.httpService.delete(this.apiUrl + '/api/Programs/DeleteCompetency?id=' + competencyId, httpOptions).pipe(retry(1), catchError(this.handleError));
    }
}