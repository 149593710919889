import { Injectable } from '@angular/core';
@Injectable()
export class SharedService {

    private apiUrl;
    private stsUrl;

    constructor() {
    // this.apiUrl = 'http://localhost:53896';
    this.apiUrl = 'https://360-feedback-api.azurewebsites.net';
     //   this.apiUrl = 'https://360-feedback-api-staging.azurewebsites.net';


    //   this.stsUrl = 'http://localhost:5000';
          this.stsUrl = 'https://360-feedback-sts.azurewebsites.net';
    }

    public getAPIUrl() {
        return this.apiUrl;
    }

    public getSTSUrl() {
        return this.stsUrl;
    }
}
