import { NgxUploaderModule } from 'ngx-uploader';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { TakeSurveyComponent } from './take-survey/take-survey.component';
import { FileValidator } from './survey/upload-final-excel/file-input.validator';

import { SharedService } from './shared/shared.service';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';

// angular material by prashant
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MatButtonModule,
  MatTableModule,
  MatCardModule,
  MatInputModule,
  MatRadioModule,
  MatGridListModule,
  MatToolbarModule,
  MatTabsModule,
  MatTooltipModule,
  MatPaginatorModule,
  MatDialogModule,
  MatExpansionModule,
  MatProgressSpinnerModule,
  MatProgressBarModule,
  MatIconModule,
  MatSelectModule,

  
} from '@angular/material';


// Authentication components
import { AuthGuard } from './route-guards/auth-guard';
import { LoginComponent } from './login/login.component';
import { AppService } from './app.service';

import { CountdownModule } from 'ngx-countdown';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AddParticipantComponent } from './add-participant/add-participant.component';
import { SurveyService } from './survey/survey.service';
import { TakeSurveyNewComponent } from './take-survey-new/take-survey-new.component';
import { ContentSafeHtmlPipe } from './shared/content-safe-html.pipe';
import { BlobErrorHttpInterceptor } from './core/http-error.interceptor';


@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    TakeSurveyComponent,
    LoginComponent,
    FileValidator,
    AddParticipantComponent,
    TakeSurveyNewComponent,
    ContentSafeHtmlPipe
  ],
  imports: [
    BrowserModule,
    RouterModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatTableModule,
    MatCardModule,
    MatInputModule,
    MatToolbarModule,
    MatTabsModule,
    MatRadioModule,
    MatGridListModule,
    HttpClientModule,
    MatTooltipModule,
    MatPaginatorModule,
    MatDialogModule,
    NgxUploaderModule,
    FormsModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatIconModule,
    CountdownModule,
    FlexLayoutModule,
    MatSelectModule,
    NgxPageScrollCoreModule
  ],
  providers: [SharedService, AuthGuard, AppService, SurveyService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BlobErrorHttpInterceptor,
      multi: true
    },],

  bootstrap: [AppComponent]
})
export class AppModule { }



