import { Component, OnInit, TemplateRef } from '@angular/core';
import { MatDialog, MatTableDataSource } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { SurveyService } from '../survey/survey.service';
import { FormGroup } from '@angular/forms';
import { AppService } from '../app.service';
import Swal from 'sweetalert2';
import validator from 'validator';

@Component({
	selector: 'app-add-participant',
	templateUrl: './add-participant.component.html',
	styleUrls: ['./add-participant.component.css']
})
export class AddParticipantComponent implements OnInit {
	// myItems =new Array[] ; 
	public tableColumns = ['name', 'email', 'role', 'action2'];
	public isemailPresent = false;
	public fname = '';
	public lname = '';
	public email = '';
	public phone = '';
	public role = '';

	flag0 = false
	flag1 = false
	flag2 = false
	flag3 = false
	flag4 = false
	flag5 = false
	mainFlag = false
	validation = false
	submitted = false


	data = {
		code: '',
		deletedInvitations: [],
		addedInvitations: [],
	}

	public raterDS;

	public existingInvitations;
	public existingInvitationData;
	participantList: any[];
	participantDataByCode;

	constructor(private dialog: MatDialog, private surveyService: SurveyService,
		private routeParams: ActivatedRoute,
		private appService: AppService) {
		this.routeParams.params.subscribe(
			response => {
				this.data.code = response.code;
			});
	}

	ngOnInit() {
		this.getRaters();
		this.getParticipantByCode();
	}

	getRaters() {
		this.appService.getRatersList(this.data.code).subscribe(
			response => {
				console.log('Raters list is', response);
				this.existingInvitations = response;
				this.existingInvitationData = new MatTableDataSource(this.existingInvitations);
			});


	}



	getParticipantByCode() {
		this.appService.getParticipantByCode(this.data.code).subscribe(
			response => {
				this.participantDataByCode = response;
				console.log('Participant list is', this.participantDataByCode);
			});
	}

	deleteInvitation(id) {
		Swal.fire({
			position: 'center',
			allowOutsideClick: false,
			allowEscapeKey: false,
			title: 'Are you sure?',
			type: 'warning',
			text: 'You will not able to revert this data!',
			showCancelButton: true,
			cancelButtonColor: '#d33',
			confirmButtonText: 'Delete'
		}).then((result) => {
			if (result.value) {

				this.data.deletedInvitations.push(id);
				this.existingInvitations.splice(this.existingInvitations.findIndex(d => d.id === id), 1);
				this.existingInvitationData = new MatTableDataSource(this.existingInvitations);
				console.log(this.data);
				this.appService.finalRaterList(this.data).subscribe(
					response => {
						console.log('Raters Final list is');
					});
				Swal.fire({
					allowOutsideClick: false,
					allowEscapeKey: false,
					title: 'Deleted!',
					text: 'Rater has been deleted.',
					type: 'success'
				});
				error => {
					console.log('Error in Deleting data...');
					console.log(error);
				}
			}
		});
	}

	submit() {
		this.data.deletedInvitations = []
		this.appService.finalRaterList(this.data).subscribe(
			response => {
				console.log('Raters Final list is', response);
				this.dialog.closeAll();
				this.submitted = true
			});
	}


	addRater(templateRef: TemplateRef<any>) {
		this.dialog.open(templateRef, {
			backdropClass: 'backdropBackground'
		});
	}



	public openAddRaterModal(modal) {
		this.dialog.open(modal)
	}



	onClick(evt) {
		console.log("Im IN");
		if (!this.role) {
			this.flag5 = true
			return false
		} else {
			this.flag5 = false
			if (this.mainFlag) {
				this.validation = true
			}
		}
	}



	isNumber(evt) {
		var iKeyCode = (evt.which) ? evt.which : evt.keyCode
		if (iKeyCode != 46 && iKeyCode > 31 && (iKeyCode < 48 || iKeyCode > 57))
			return false;
		return true;
	}

	onInput(data) {

		if (data.target.name === 'fname') {
			if (!this.fname) {
				this.flag0 = true
				return false
			} else {
				this.flag0 = false
			}
		}


		if (data.target.name === 'lname') {
			if (!this.lname) {
				this.flag1 = true
				return false
			} else {
				this.flag1 = false
			}
		}


		if (data.target.name === 'email') {
			if (!this.email) {
				this.flag2 = true
				this.flag3 = false
				return false
			} else if (!validator.isEmail(this.email)) {
				this.flag2 = false
				this.flag3 = true
				return false
			} else {
				this.flag2 = false
				this.flag3 = false
			}
		}

		if (data.target.name === 'phone') {
			if (!this.phone) {
				this.flag5 = true
				return false
			} else {
				this.flag5 = false
			}
		}

		if (this.fname && this.lname && this.email && this.phone) {
			this.mainFlag = true
		}
	}


	addFormData() {
		let obj = {
			firstName: this.fname,
			lastName: this.lname,
			email: this.email,
			phone: this.phone,
			relationship: this.role,
		}

		if (this.existingInvitations.find(d => d.respondentEmail === this.email) != undefined || this.data.addedInvitations.find(d => d.email === this.email)) {
			Swal.fire("Email address already exists");
		} else {
			this.data.addedInvitations.push(JSON.parse(JSON.stringify(obj)));
			this.raterDS = new MatTableDataSource(this.data.addedInvitations);
			this.dialog.closeAll();
			console.log(this.data);
			this.fname = '';
			this.lname = '';
			this.email = '';
			this.phone = '';
			this.role = '';
		}
	}

	deleteRater(i) {
		this.data.addedInvitations.splice(i, 1);
		this.raterDS = new MatTableDataSource(this.data.addedInvitations);
		console.log(this.data.addedInvitations);
	}
}



