import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    RouterStateSnapshot,
    Route,
    Router,
    CanLoad
} from '@angular/router';


@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {



    constructor(private route: Router) { }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        let url: string = state.url;
        return this.checkLogin(url);
    }

    public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.canActivate(route, state);
    }

    public canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {

        let url = `/${route.path}`;
        return this.checkLogin(url);
    }

    private checkLogin(url: string) {
        if (localStorage.getItem('token')) {
            return true;
        }
        else {
            this.route.navigate(['/login']);
            localStorage.removeItem('token');
            return false;
        }
    }

}
