import { QuestionOption, SurveyQuestionModel } from '../app.model';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild, TemplateRef, HostListener, Renderer2, Inject, } from '@angular/core';
import { AppService } from '../app.service';
import { Title, DOCUMENT } from '@angular/platform-browser';
import { MatDialog } from '@angular/material';
import Swal from 'sweetalert2';
import { SurveyService } from '../survey/survey.service';
import { PageScrollService } from 'ngx-page-scroll-core';

@Component({
  selector: 'app-take-survey-new',
  templateUrl: './take-survey-new.component.html',
  styleUrls: ['./take-survey-new.component.css',]
})
export class TakeSurveyNewComponent implements OnInit {

  @ViewChild('mySelect') mySelect;

  [x: string]: Object;
  progresValue: number;
  rangeArray: number[];
  public currentStatus = '';
  public programUId = '';
  public loading;
  public scrolling = false;
  public feedbackProgramDetails;
  public feedbackInvitations;
  public selectedInvitation: any;
  public selectedParticipant: any;
  public selectedRespondent: any;
  public selectedlanguage = '';
  public feedbackProgramQuestionSet;
  public currentPageNumber = 1;
  public nextBtnHidden = false;
  public testStartTime = 0;
  public continuousFunction;
  public enableTimer = false;
  public testTimeTaken = 0;
  public answeredQuestionsLength: any;
  public participantById: any;
  public respondentById: any;
  public instructionData;
  public currentQuestionNumber = 1;
  public opened = false;
  public isSubmitted = false;

  @ViewChild('instructionsModal') instructionsModal: TemplateRef<any>;
  testInstructions: Object;

  constructor(private appService: AppService,
    private pageScrollService: PageScrollService,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private surveyService: SurveyService, private router: Router, private title: Title, public dialog: MatDialog) {
    // let surveyLinkId = localStorage.getItem('surveyLinkId') || '';
    // if (surveyLinkId !== '' && surveyLinkId !== undefined) { 
    //   this.surveyLinkId = surveyLinkId;
    //   this.getSurveyData();
    // } else {
    //   this.getParticipantsForRespondent();
    //   this.surveyLinkId = '';
    // }
    // this.progresValue =this.feedbackProgramQuestionSet.length;
    this.progresValue = 0;
    this.rangeArray = new Array(100);

  }

  ngOnInit() {
    // debugger;
    let script = this._renderer2.createElement('script');
    script.type = `text/javascript`;
    script.text = `
      document.onkeydown = function (t) {
        if (t.which == 9) {
          return false;
        }
      }`;
    this._renderer2.appendChild(this.document.body, script);
    this.GetFeedbackProgramForParticipant();
  }
 
   


  // public getAllParticipantsById(participantId) {
  //   this.appService.getParticipantById(participantId).subscribe(response => {
  //     this.participantById = response;
  //   }, error => {
  //     console.log(error);
  //   })
  // }

  // public getAllRespondentsById(respondentid) {
  //   this.appService.getRespondentById(respondentid).subscribe(response => {
  //     this.respondentById = response;
  //   }, error => {
  //     console.log(error);
  //   })
  // }


  GetFeedbackProgramForParticipant() {
    this.loading = true;
    this.appService.GetFeedbackProgramForParticipant().subscribe(
      response => {
        this.feedbackProgramDetails = response;
        this.programUId = this.feedbackProgramDetails.uId;
        this.title.setTitle('HR Anexi Assessments');
        if (this.feedbackProgramDetails.isPublished) {
          this.getFeedbackInvitations();
        } else {
          this.currentStatus = 'Survey-Closed';
        }
        this.loading = false;
      }, error => {
        console.log(error);
        this.loading = false;
      });
  }

  getFeedbackInvitations() {
    this.appService.getFeedbackInvitations().subscribe(response => {
      this.feedbackInvitations = response;
      if (this.feedbackInvitations.length > 0) {
        this.currentStatus = 'Select-Participant';
        this.getQuestionSet();
      } else {
        this.currentStatus = 'Survey-Already-Completed';
      }
    })
  }

  public selectParticipant() {
    localStorage.setItem('selectedInvitationCode', this.selectedInvitation.code);
    // let participantid = this.selectedInvitation.participantId;
    // let respondentid = this.selectedInvitation.respondentId;
    // this.getAllParticipantsById(participantid);
    // this.getAllRespondentsById(respondentid);
    this.getAnswerSet();
    if (this.feedbackProgramDetails.languages.length === 1) {
      this.selectedlanguage = this.feedbackProgramDetails.languages[0];
      this.startFeedback();
    } else {
      this.currentStatus = 'Select-Language';
    }
  }

  public startFeedback() {
    if (this.feedbackProgramDetails.showInstruction) {
      this.showInstruction();
    } else {
      this.currentStatus = 'Question-Answer';

    }
  }

  public showInstruction() {
    this.currentStatus = 'Show-Instruction'
    this.surveyService.getAllSurveyInstructions(this.programUId).subscribe(
      response => {
        this.instructionData = response[0].instruction;

        this.currentStatus = 'instruction';
      })
  }



  public viewQuestions() {
    this.scrollToQuestion(1);
    let currentQuestionNumber = 1;
    for (let i = 0; i < this.feedbackProgramQuestionSet.questions.length; ++i) {
      if (!this.isQuestionDisabled(i)) {
        currentQuestionNumber = i + 1;
      }
    }
    this.currentStatus = 'Question-Answer';
    if (currentQuestionNumber !== 1) {
      this.scrollToQuestion(currentQuestionNumber);
    }
    else {
      window.scrollTo(0, 0);
    }
  }

  public getQuestionSet() {
    this.appService.getFeedbackProgramQuestionSet().subscribe(
      response => {
        this.feedbackProgramQuestionSet = response;

        this.sortQuestionsByOrder();
      },

      error => {
        console.log('Error in getting data');
        console.log(error);
      });
  }

  private sortQuestionsByOrder() {
    this.feedbackProgramQuestionSet.questions.forEach(set => {
      set.options.sort((a, b) => a.order - b.order);
    })
  }




  public getAnswerSet() {

    this.appService.getAnswerSet(this.selectedInvitation.code).subscribe(
      response => {
        if (response != null) {
          let qResponses = response['responses'];
          let timeTaken = '';
          let pageOfTimeTaken = 0;
          let currentPageNumberSet = false;
          qResponses.forEach(responseElement => {
            const selectedQuestion = this.feedbackProgramQuestionSet.questions.find(q => q.code === responseElement.questionCode);
            if (responseElement.responseCode !== '' && responseElement.responseCode !== null) {
              if (selectedQuestion != null) {
                selectedQuestion.response = responseElement.responseCode;
                selectedQuestion.value = responseElement.value;
                if (this.feedbackProgramDetails.showTimer) {
                  selectedQuestion.timeTaken = responseElement.timeTaken;
                  timeTaken = responseElement.timeTaken;
                  pageOfTimeTaken = selectedQuestion.pageNumber;
                }
              }
            } else {
              if (selectedQuestion.pageNumber >= this.currentPageNumber && !currentPageNumberSet) {
                this.currentPageNumber = selectedQuestion.pageNumber;
                currentPageNumberSet = true;
                if (this.currentPageNumber > pageOfTimeTaken) {
                  timeTaken = '';
                }
              }
            }
          });
          if (timeTaken !== '') {
            this.testTimeTaken = Number(timeTaken);
          }
        }


      },
      error => {
        console.log('Error in getting data');
        console.log(error);
      }
    );
  }






  public getCustomizedMessage() {
    let customizedMessageText = '';
    if (this.feedbackProgramDetails.customizedMessage) {
      customizedMessageText = this.feedbackProgramDetails.customizedMessageText;
      customizedMessageText = customizedMessageText.replace('###ParticipantName###', this.selectedInvitation.participantName);
      customizedMessageText = customizedMessageText.replace('###ParticipantEmail###', this.selectedInvitation.participantEmail);
      customizedMessageText = customizedMessageText.replace('###RespondentName###', this.selectedInvitation.respondentName);
      customizedMessageText = customizedMessageText.replace('###RespondentEmail###', this.selectedInvitation.respondentEmail);

      customizedMessageText = customizedMessageText.replace('###PColumn1###', this.participantById.column1);
      customizedMessageText = customizedMessageText.replace('###PColumn2###', this.participantById.column2);
      customizedMessageText = customizedMessageText.replace('###PColumn3###', this.participantById.column3);
      customizedMessageText = customizedMessageText.replace('###PColumn4###', this.participantById.column4);
      customizedMessageText = customizedMessageText.replace('###PColumn5###', this.participantById.column5);
      customizedMessageText = customizedMessageText.replace('###PColumn6###', this.participantById.column6);
      customizedMessageText = customizedMessageText.replace('###PColumn7###', this.participantById.column7);
      customizedMessageText = customizedMessageText.replace('###PColumn8###', this.participantById.column8);
      customizedMessageText = customizedMessageText.replace('###PColumn9###', this.participantById.column9);
      customizedMessageText = customizedMessageText.replace('###PColumn10###', this.participantById.column10);


      customizedMessageText = customizedMessageText.replace('###RColumn1###', this.respondentById.column1);
      customizedMessageText = customizedMessageText.replace('###RColumn2###', this.respondentById.column2);
      customizedMessageText = customizedMessageText.replace('###RColumn3###', this.respondentById.column3);
      customizedMessageText = customizedMessageText.replace('###RColumn4###', this.respondentById.column4);
      customizedMessageText = customizedMessageText.replace('###RColumn5###', this.respondentById.column5);
      customizedMessageText = customizedMessageText.replace('###RColumn6###', this.respondentById.column6);
      customizedMessageText = customizedMessageText.replace('###RColumn7###', this.respondentById.column7);
      customizedMessageText = customizedMessageText.replace('###RColumn8###', this.respondentById.column8);
      customizedMessageText = customizedMessageText.replace('###RColumn9###', this.respondentById.column9);
      customizedMessageText = customizedMessageText.replace('###RColumn10###', this.respondentById.column10);
    }
    return customizedMessageText;
  }



  getQuestionText(question: SurveyQuestionModel) {
    let questionText = '';

    if (question.dependentOn > 0) {
      if (this.selectedlanguage !== 'English') {
        if (question.texts.find(t => t.language === this.selectedlanguage && t.ifPreviousAnswer === this.feedbackProgramQuestionSet.questions.find(q => q.order === question.dependentOn).response) !== undefined) {
          questionText = question.texts.find(t => t.language === this.selectedlanguage && t.ifPreviousAnswer === this.feedbackProgramQuestionSet.questions.find(q => q.order === question.dependentOn).response).text + ' (';
        }
      }

      if (question.texts.find(t => t.language === 'English' && t.ifPreviousAnswer === this.feedbackProgramQuestionSet.questions.find(q => q.order === question.dependentOn).response) !== undefined) {
        questionText = questionText + question.texts.find(t => t.language === 'English' && t.ifPreviousAnswer === this.feedbackProgramQuestionSet.questions.find(q => q.order === question.dependentOn).response).text;
      }

      if (this.selectedlanguage !== 'English' && questionText !== '') {
        questionText = questionText + ')';
      }
    } else {
      if (this.selectedlanguage !== 'English') {
        questionText = question.texts.find(t => t.language === this.selectedlanguage).text + ' (';
      }

      questionText = questionText + question.texts.find(t => t.language === 'English').text;

      if (this.selectedlanguage !== 'English') {
        questionText = questionText + ')';
      }
    }
    return questionText;
  }

  // getQuestionText(question: SurveyQuestionModel) {
  //   let engQuestionText = '';
  //   let langQuestionText = '';
  //   let questionText = '';
  //   if (question.dependentOn > 0) {
  //     if (this.selectedlanguage !== 'English') {
  //       if (question.texts.find(t => t.language === this.selectedlanguage && t.ifPreviousAnswer === this.feedbackProgramQuestionSet.questions.find(q => q.order === question.dependentOn).response) !== undefined) {
  //         langQuestionText = question.texts.find(t => t.language === this.selectedlanguage && t.ifPreviousAnswer === this.feedbackProgramQuestionSet.questions.find(q => q.order === question.dependentOn).response).text ;
  //       }
  //     }
  //     if (question.texts.find(t => t.language === 'English' && t.ifPreviousAnswer === this.feedbackProgramQuestionSet.questions.find(q => q.order === question.dependentOn).response) !== undefined) {
  //       engQuestionText =  question.texts.find(t => t.language === 'English' && t.ifPreviousAnswer === this.feedbackProgramQuestionSet.questions.find(q => q.order === question.dependentOn).response).text;
  //     }

  //     if (this.selectedlanguage !== 'English' && langQuestionText !== '') {
  //       questionText = langQuestionText + "<br><span class='take-survey-eng-question-text'> (" + engQuestionText + ") </span>"
  //     } else {
  //       questionText = engQuestionText;
  //     }
  //   } else {
  //     if (this.selectedlanguage !== 'English') {
  //       langQuestionText = question.texts.find(t => t.language === this.selectedlanguage).text;
  //     }

  //     engQuestionText = question.texts.find(t => t.language === 'English').text;
      
  //     if (this.selectedlanguage !== 'English' && langQuestionText !== '') {
  //       questionText = langQuestionText + "<br><span class='take-survey-eng-question-text'> (" + engQuestionText + ") </span>"
  //     } else {
  //       questionText = engQuestionText;
  //     }  
  //   }
  //   return questionText;
  // }

  clearDependentAnswer(question) {
    if (this.feedbackProgramQuestionSet.questions.find(q => q.dependentOn > 0 && q.dependentOn === question.order) !== undefined) {
      this.feedbackProgramQuestionSet.questions.find(q => q.dependentOn > 0 && q.dependentOn === question.order).response = '';
    }
  }

  getQuestionOptionText(question: SurveyQuestionModel, option: QuestionOption) {
    let optionText = '';
    let otherLanguageOptionText = '';
    let englishText = '';
    if (question.dependentOn > 0) {
      if (this.feedbackProgramQuestionSet.questions.find(q => q.order === question.dependentOn).response !== option.ifPreviousAnswer) {
        return '';
      } else {
        if (this.selectedlanguage !== 'English') {
          optionText = option.languageOptions.find(o => o.language === this.selectedlanguage).text + ' (';
        }
        optionText = optionText + option.languageOptions.find(o => o.language === 'English').text;
        if (this.selectedlanguage !== 'English' && otherLanguageOptionText !== '') {
          if (question.type === 'rating') {
            optionText = otherLanguageOptionText + "<br><span class='take-survey-eng-option-text'> (" + englishText + ") </span>"
          } else {
            optionText = otherLanguageOptionText + "<span class='take-survey-eng-option-text'> (" + englishText + ") </span>"
          }
        } else {
          optionText = englishText;
        }
        return optionText;
      }
    } else {
      if (this.selectedlanguage !== 'English') {
        otherLanguageOptionText = option.languageOptions.find(o => o.language === this.selectedlanguage).text.toString();
      }
      englishText = optionText.toString() + option.languageOptions.find(o => o.language === 'English').text.toString();
      if (this.selectedlanguage !== 'English' && otherLanguageOptionText !== '') {
        // optionText = '\u202A' + otherLanguageOptionText + '\u202C' + "  ( " + englishText + " )";
        // optionText = otherLanguageOptionText + "<span class='take-survey-eng-option-text'> (" + englishText + ") </span>";
        if (question.type === 'rating' ) {
          optionText = otherLanguageOptionText + "<br><span class='take-survey-eng-option-text'> (" + englishText + ") </span>"
        } else {
          optionText = otherLanguageOptionText + "<span class='take-survey-eng-option-text'> (" + englishText + ") </span>"
        }
      } else {
        optionText = englishText;
      }
      return optionText;
    }
  }

  getQuestionOptionTextInEnglish(question: SurveyQuestionModel, option: QuestionOption) {
    let optionText = '';
    optionText = optionText + option.languageOptions.find(o => o.language === 'English').text;
    return optionText;
  }

  // getQuestionOptionValue(question) {
  //   let optionValue = '';
  //   optionValue = question.options.find(d => d.value === question.value).languageOptions.find(o => o.language === 'English').text;
  //   return optionValue;
  // }
  getQuestionOptionValue(option: QuestionOption) {
    let optionValue = '';
    optionValue = option.languageOptions.find(o => o.language === 'English').text;
    return optionValue;
  }

  checkboxOptionChanged(e, q, o) {

    if (e.target.checked) {
      if (q.response !== undefined) {

        var n = this.occurrences(q.response, '##', false);

        if (n < q.minSelect || n < q.maxSelect) {
          q.response = q.response + e.target.value + '##';

        } else {
          e.target.checked = false;
        }
      } else {
        q.response = e.target.value + '##';
      }
      // console.log(q.response);
    } else {
      q.response = q.response.replace(e.target.value + '##', '');
      // console.log(q.response);
    }
    if (this.isMaxReached(q)) {
      this.scrollToQuestion(this.currentQuestionNumber + 1);
    }
  }

  isMaxReached(question) {
    if (question.response) {
      let count = (question.response.match(/##/g) || []).length;
      if (count === question.maxSelect) {
        return true;
      }
      else {
        return false
      }
    } else {
      return false;
    }
  }

  occurrences(string, subString, allowOverlapping) {
    string += "";
    subString += "";
    if (subString.length <= 0) return (string.length + 1);

    var n = 0,
      pos = 0,
      step = allowOverlapping ? 1 : subString.length;

    while (true) {
      pos = string.indexOf(subString, pos);
      if (pos >= 0) {
        ++n;
        pos += step;
      } else break;
    }
    return n;
  }

  isThisChecked(q, option) {
    if (q.response !== undefined) {
      let optionValue = '';
      optionValue = option.languageOptions.find(o => o.language === 'English').text;
      if (q.response.indexOf(optionValue) >= 0) {
        return true;
      } else {
        return false;
      }
    }
  }




  isSubmitEnabled() {
    let shouldSubmitBeEnabled = true;
    for (let i = 0; i < this.feedbackProgramQuestionSet.questions.length; ++i) {
      if (this.isQuestionDisabled(i)) {
        shouldSubmitBeEnabled = false;
      }
    }
    return shouldSubmitBeEnabled;
  }
  // isSubmitEnabled() {
  //   let shouldSubmitBeEnabled = true;
  //   for (let i = 0; i < this.feedbackProgramQuestionSet.questions.length; ++i) {
  //     if (this.feedbackProgramQuestionSet.questions[i].response === undefined || this.feedbackProgramQuestionSet.questions[i].response === '' || this.feedbackProgramQuestionSet.questions[i].response === null) {
  //       shouldSubmitBeEnabled = false;
  //       break;
  //     }
  //   }
  //   return shouldSubmitBeEnabled;
  // }


  markMultitextResponed(question) {
    let responseString = '';
    question.options.forEach(o => {
      if (o.response !== undefined && o.response.trim() !== '') {
        responseString = responseString + o.response + '#';
      } else {
        responseString = responseString + ' ' + '#';
      }
    });
    question.response = responseString;
  }

  getSavedResponses() {
    let timeTaken = 0;
    if (this.feedbackProgramDetails.showTimer) {
      let date = new Date();
      let stopTime = date.getTime() / 1000;
      timeTaken = Math.ceil(stopTime - this.testStartTime);
    }
    let responses: Response[] = [];
    this.feedbackProgramQuestionSet.questions.forEach(d => {
      const response = new Response();
      response.questionCode = d.code;
      response.responseCode = d.response;
      response.value = d.value;
      if (this.feedbackProgramDetails.showTimer) {
        if (d.pageNumber === this.currentPageNumber) {
          d.timeTaken = timeTaken.toString();
          response.timeTaken = timeTaken.toString();
        } else {
          response.timeTaken = d.timeTaken;
        }
      }
      responses.push(response);
    });
    return responses;
  }

  public saveAnswers() {
   
      let responses: Response[] = [];
      this.feedbackProgramQuestionSet.questions.forEach(d => {
        const response = new Response();
        response.questionCode = d.code;
        response.responseCode = d.response;
        response.value = d.value;
        responses.push(response);
      });
      const responsequestionSet = {
       
        ProgramUId: this.feedbackProgramDetails.uId,
        programCode: this.feedbackProgramDetails.code,
        invitationCode: this.selectedInvitation.code,
        participantId: this.selectedInvitation.participantId,
        respondentId: this.selectedInvitation.respondentId,
        Responses: responses,
      };

      this.appService.SaveAnswer(responsequestionSet).subscribe(
        response => {
        }, errorResponse => {
          console.log(errorResponse);
          if (errorResponse.error.message === 'Survey Closed') {
            Swal.fire("Feedback is closed").then(response => {
              console.log("Feedback closed in swal");
              window.location.reload();
            })
          }
        });
    
  }

  public constructResponseQuestionSet() {
    let response = {
      programUId: this.feedbackProgramDetails.uId,
      programCode: this.feedbackProgramDetails.code,
      invitationCode: this.selectedInvitation.code,
      participantId: this.selectedInvitation.participantId,
      respondentId: this.selectedInvitation.respondentId,
      responses: this.getSavedResponses()
    };
    return response
  }

  public CheckSurveyTOSubmit() {
    let submit = false;
    submit = this.feedbackProgramQuestionSet.questions.every(d => d.response !== '' && d.response !== undefined && d.response !== null);
    return submit;
  }

  checkScrollToQuestion(index) {
    let question = this.feedbackProgramQuestionSet.questions[index-2];
    var scroll = true;
    if (question.response === 'other' || question.response === 'Other' || question.response === 'others' || question.response === 'Others') {
      if (question.otherResponseText === undefined || question.otherResponseText === null || question.otherResponseText === "") {
        scroll = false;
      }
    }
    if (scroll) {
      this.scrollToQuestion(index);
    }
  }
  public getProgressbarValue() {
    let completed = 0;
    for (let i = 0; i < this.feedbackProgramQuestionSet.questions.length; ++i) {
      if (!this.isQuestionDisabled(i)) {
        completed += 1;
      }
    }
    return completed / this.feedbackProgramQuestionSet.questions.length * 100;
  }


  SubmitTest() {
    if (!this.isSubmitted) {
      this.isSubmitted = true;
      this.loading = true;
      const responsequestionSet = this.constructResponseQuestionSet();
      this.appService.SaveQuestionSetResponses(responsequestionSet).subscribe(
        response => {
          this.loading = false;
          this.isSubmitted = false;
          this.currentStatus = 'Survey-Completed';
          this.checkRemainingParticipants();

        }, error => {
          this.loading = false;
          console.log('API ERROR:', error);
        });
    } else {
      this.isSubmitted = false;
      Swal.fire('Please answer all questions');
    }
  }

  checkRemainingParticipants() {
    this.appService.getFeedbackInvitations().subscribe(response => {
      this.feedbackInvitations = response;
    });
  }

  showParticipantList() {
    window.location.reload();
  }

  logout() {
    Swal.fire({
      position: 'center',
      allowOutsideClick: false,
      allowEscapeKey: false,
      title: 'Logout',
      type: 'warning',
      text: 'Are you sure to logout?',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      confirmButtonText: 'ok'
    }).then((result) => {
      if (result.value) {
        localStorage.clear();
        this.router.navigate(['/login']);
      }
    });
  }

  gotoPreviousQuestion() {
    if (this.currentQuestionNumber > 1) {
      this.scrollToQuestion(this.currentQuestionNumber - 1);
    }
  }

  gotoNextQuestion() {
    this.scrollToQuestion(this.currentQuestionNumber + 1);
  }

  textChanged(q) {
    if (this.currentQuestionNumber < this.feedbackProgramQuestionSet.questions.length) {
      if (q.response.trim() !== "") {
        this.scrollToQuestion(this.currentQuestionNumber + 1);
      }
    }
  }

  saveAnswersInOpenText(response) {
    if (response !== null && response !== undefined && response !== "") {
      this.saveAnswers();
    }
  }

  public scrollToQuestion(index) {

    let offset = 200;

    setTimeout(() => {
      this.currentQuestionNumber = index;

      this.scrolling = true;
      this.pageScrollService.scroll({
        document: this.document,
        scrollOffset: offset,
        duration: 400,
        scrollTarget: '#question-' + index.toString()
      });

      if (this.feedbackProgramQuestionSet.questions[index - 1].type === 'text') {
        let elem = "open-text-" + index.toString();
        document.getElementById(elem).focus();
      }

      setTimeout(() => {
        this.scrolling = false;
      }, 500);
    }, 500);
  }

  isNextQuestionDisabled() {
    let index = this.currentQuestionNumber - 1;
    return this.isQuestionDisabled(index);
  }

  clickedOnDisabledQuestion(index) {
    if (index === this.currentQuestionNumber - 2) {
      this.scrollToQuestion(index+1);
    } else if (index === this.currentQuestionNumber) {
      if (!this.isNextQuestionDisabled()) {
        this.scrollToQuestion(index + 1);
      }
    }
  }



  myScrollHandler(event) {
    if (!this.scrolling) {
      let currentDiv = 1;
      let currentDiff = 100000;
      this.feedbackProgramQuestionSet.questions.forEach((element, index) => {
        let rDiv = document.getElementById("question-reference-div");
        let rRect = rDiv.getBoundingClientRect();
        let rHeight = (rRect.top + rRect.bottom) / 2;

        let qDiv = document.getElementById('question-' + (index + 1).toString());
        let qRect = qDiv.getBoundingClientRect();
        let qTop = qRect.top;
        let qBottom = qRect.bottom;

        let topDiff = rHeight - qTop;
        if (topDiff < 0) {
          topDiff = topDiff * -1;
        }
        let bottomDiff = rHeight - qBottom;
        if (bottomDiff < 0) {
          bottomDiff = bottomDiff * -1;
        }

        if (topDiff < currentDiff) {
          currentDiff = topDiff;
          currentDiv = index + 1;
        }

        if (bottomDiff < currentDiff) {
          currentDiff = rHeight - qBottom;
          currentDiv = index + 1;
        }
      });

      if (currentDiv == 1 || !this.isQuestionDisabled(currentDiv - 2)) {
        this.currentQuestionNumber = currentDiv;
      }
    }
  }

  isQuestionDisabled(index) {
    let question = this.feedbackProgramQuestionSet.questions[index];
    if (this.getQuestionText(question).toLowerCase() === 'not applicable') {
      return false
    } else if (question !== 'multi-text-mandatory') {
      if (question.response === undefined || question.response.trim() === '' || question.response === null) {
        return true;
      } else if (question.response === 'other' || question.response === 'Other' || question.response === 'others' || question.response === 'Others') {
        if (question.otherResponseText === undefined || question.otherResponseText === null || question.otherResponseText.trim() === "") {
          return true;
        }
      }
      if (question.type === 'multiple') {
        let count = (question.response.match(/##/g) || []).length;
        if (count < question.minSelect || count > question.maxSelect) {
          return true;
        }
      }
    } else {
      question.options.forEach(o => {
        if (o.response === undefined || o.response.trim() === '' || o.response === null) {
          return true;
        }
      });
    }
    return false;
  }

  changeCLass() {
    this.opened = !this.opened;
  
  }

  openPanel() {

    this.mySelect.open();
  }
  closePanel() {

    this.mySelect.close();
  }
}



class Response {
  questionCode: string;
  responseCode: string;
  value: number;
  otherResponseText: string;
  timeTaken: string;
}