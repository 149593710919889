import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { SharedService } from './shared/shared.service';

@Injectable()
export class AppService {
    private apiUrl = '';
    constructor(private httpService: HttpClient, private sharedService: SharedService) {
        this.apiUrl = this.sharedService.getAPIUrl();
    }

    GetFeedbackProgramForParticipant() {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        return this.httpService.get(this.apiUrl + '/api/Programs/GetFeedbackProgramForParticipant', httpOptions)
            .pipe(retry(3), catchError(this.handleError));
    }


    public getFeedbackInvitations() {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        return this.httpService.get(this.apiUrl + '/api/Participants/getFeedbackInvitations', httpOptions)
            .pipe(retry(3), catchError(this.handleError));
    }


    public getFeedbackProgramQuestionSet() {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        return this.httpService.get(this.apiUrl + '/api/ProgramQuestionSets/getFeedbackProgramQuestionSet', httpOptions)
            .pipe(retry(3), catchError(this.handleError));
    }


    public getAnswerSet(invitationCode) {
        return this.httpService.get(this.apiUrl + '/api/ProgramQuestionSetResponses/GetByInvitationCode?invitationCode=' + invitationCode).pipe(retry(3), catchError(this.handleError));
    }


    public SaveQuestionSetResponses(responsequestionSet) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const formData = JSON.stringify(responsequestionSet);
        return this.httpService.post(this.apiUrl + '/api/ProgramQuestionSetResponses/Post', formData, httpOptions)
            .pipe(retry(3), catchError(this.handleError));
    }
    public SaveAnswer(responsequestionSet) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const formData = JSON.stringify(responsequestionSet);
        return this.httpService.post(this.apiUrl + '/api/ProgramQuestionSetResponses/SaveAnswers', formData, httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }


    
    public GetFeedbackProgramByUId(uId) {
        return this.httpService.get(this.apiUrl + '/api/Programs/GetFeedbackProgramByUId?uId=' + uId)
            .pipe(retry(3), catchError(this.handleError));
    }

    public getInvitationDetails(invitationCode) {
        return this.httpService.get(this.apiUrl + '/api/Surveys/GetInvitationDetails?invitationCode=' + invitationCode).pipe(retry(3), catchError(this.handleError));
    }


    public getSurveyQuestionSet(uid) {
        return this.httpService.get(this.apiUrl + '/api/SurveyQuestionSets/GetBySurveyUId?uId=' + uid).pipe(retry(3), catchError(this.handleError));
    }

    // this method returns list of survey
    public getSurveyList() {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        return this.httpService.get(this.apiUrl + '/api/Surveys/GetAll', httpOptions)
            .pipe(retry(3), catchError(this.handleError));
    }



     // this method returns all client list
    public getClientList() {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        const headers = new Headers();
        headers.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
        return this.httpService.get(this.apiUrl + '/api/Clients/GetAll', httpOptions).pipe(retry(3), catchError(this.handleError));
    }



    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        // return an observable with a user-facing error message
        return throwError(error);
    }
    
    //Final rater List
    public finalRaterList(data) {
        // const data = JSON.stringify(model);
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        let body = JSON.stringify(data);
        return this.httpService.post(this.apiUrl + '/api/Participants/AddOrDeleteInvitation', body, httpOptions)
            .pipe(retry(3), catchError(this.handleError));
    }

    //get rater list
    public getRatersList(code) {
        return this.httpService.get(this.apiUrl + '/api/Participants/GetInvitationsByCode?code=' + code).pipe(retry(3), catchError(this.handleError));
    }

    public getParticipantByCode(code) {
        return this.httpService.get(this.apiUrl + '/api/Participants/GetParticipantByCode?code=' + code).pipe(retry(3), catchError(this.handleError));
    }

    public getParticipantById(id) {
        return this.httpService.get(this.apiUrl + '/api/Participants/GetParticipantById?id=' + id).pipe(retry(3), catchError(this.handleError));
    }

    public getRespondentById(id) {
        return this.httpService.get(this.apiUrl + '/api/Participants/GetRespondentById?id=' + id).pipe(retry(3), catchError(this.handleError));
    }
}
