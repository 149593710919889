import { AuthGuard } from './route-guards/auth-guard';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { NgModule } from '@angular/core';
import {
    RouterModule,
    Routes,
    PreloadingStrategy,
    PreloadAllModules,
    CanActivate,
    ɵROUTER_PROVIDERS,
} from '@angular/router';

import { TakeSurveyComponent } from './take-survey/take-survey.component';
import { AddParticipantComponent } from './add-participant/add-participant.component';
import { TakeSurveyNewComponent } from './take-survey-new/take-survey-new.component';

export const appRoutes: Routes = [
    // { path: '**', redirectTo: 'login' },
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    {
        path: 'login',
        component: LoginComponent,
    },
    {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard]
    },

    {
        path: 'accounts',
        loadChildren: './accounts/accounts.module#AccountsModule',
        canLoad: [AuthGuard]
    },
    {
        path: 'survey',
        loadChildren: './survey/survey.module#SurveyModule',
        canLoad: [AuthGuard]
    },
    {
        path: 'client',
        loadChildren: './clients/clients.module#ClientsModule',
        canLoad: [AuthGuard]
    }, {
        path: 'users',
        loadChildren: './users/users.module#UsersModule',
        canLoad: [AuthGuard]
    },

    { path: 'take-survey', component: TakeSurveyComponent, canActivate: [AuthGuard] },

    { path: 'take-survey-new', component: TakeSurveyNewComponent, canActivate: [AuthGuard] },

    { path: 'add-rater/:code', component: AddParticipantComponent }
];
@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, {
            useHash: false, preloadingStrategy: PreloadAllModules,
            enableTracing: false,
            onSameUrlNavigation: 'reload',
            scrollPositionRestoration: 'enabled'})
    ],
    exports: [
        RouterModule
    ]

})
export class AppRoutingModule { }
