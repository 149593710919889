import { DataSource } from '@angular/cdk/collections';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';
// tslint:disable-next-line:no-empty-interface
export interface ClientData { }
// tslint:disable-next-line:no-empty-interface
export interface SurveyData { }
export interface ReportData { }
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  clientListDataTable = ['clientName', 'adminName'];
  clients: any;
  SurveyListDataTable = ['title', 'clientUId', 'languages'];
  surveyList: any;
  // MdPaginator InputsFgo
  length = 100;
  pageSize = 10;
  pageSizeOptions = [5, 10, 25, 100];
   setPageSizeOptions(setPageSizeOptionsInput: string) {
     this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
  }

   constructor(private route: Router, private appService:AppService) {

  }

  ngOnInit() {
    this.getClient();
    this.getSurvey();
  }

  public getClient() {
    this.appService.getClientList().subscribe(
      response => {
        this.clients = response;
      },
      error => {
        console.log('Error in getting Client List...');
        console.log(error);
        if (error.status == 401) {
          this.route.navigate(['/login']);
        }
      });
  }


  public getSurvey() {
    this.appService.getSurveyList().subscribe(
      response => {
        this.surveyList = response;
      },
      error => {
        console.log('Error in getting Survey List...');
        console.log(error);
        if (error.status == 401) {
          this.route.navigate(['/login']);
        }
      });
  }

  public goToSurveyList(uId) {
    this.route.navigate(['/survey/list']);
  }
}

